import { BookableNight, BookableNights, StayNight } from './types';
import { getPriceInXDays } from './utils';

const MAX_STAY_NIGHTS = 61;
const NO_STAY_VALUE = 0;

/**
 * Given a sorted list of prices for each length of stay; get the applicable nightly price
 * - Can assume the prices array will always have a nightly price where minStay = 0
 * - Can assume the prices array will be sorted in increaing order of minStay (for performance)
 *
 * @param prices {NightlyPriceAmount[]}
 * @param lengthOfStay {number}
 * @returns {number} a single nights rate for a given date and the total LOS
 */
const getAvailablePricePoint = (
	prices: Record<string, number>,
	lengthOfStay: number
) => {
	// gets all of the available LOS price points for a given date
	const priceCheckpoints = Object.keys(prices)
		.map((k) => parseInt(k, 10))
		.sort((a, b) => a - b);

	// gets the next applicable price point
	const nextApplicable = priceCheckpoints.filter((num) => num > lengthOfStay);
	// gets the largest price point we can actually apply right now based on LOS
	const largestApplicable = priceCheckpoints.filter(
		(num) => num <= lengthOfStay
	);
	return {
		nextApplicable: nextApplicable[0] ?? Infinity,
		largestApplicable: largestApplicable.length
			? largestApplicable[largestApplicable.length - 1]
			: 0,
	};
};

/**
 * Given a list of stay nights, iterate through each; regenerating subsequent
 * compound pricing if at any point we hit a new price checkpoint.
 *
 * @param stayNights {StayNight[]}
 * @param los {number}
 * @returns
 */
const refreshStayNights = (stayNights: StayNight[], los: number) => {
	// for the given LOS determine if a new pricing checkpoint has been reached
	// i.e for a given past date a new discount criteria has been met
	const revalFrom = stayNights.findIndex((sn) => sn.nextCheckpoint === los);
	if (revalFrom === -1) {
		return stayNights;
	}
	// iterate through all subsequent nights regenerating pricing
	for (let i = revalFrom; i < stayNights.length; i++) {
		const stayNight = stayNights[i];
		if (stayNights[i].nextCheckpoint === los) {
			const pricePoint = getAvailablePricePoint(stayNight.night.price, los);
			stayNights[i].nextCheckpoint = pricePoint.nextApplicable;
			stayNights[i].price =
				stayNight.night.price[pricePoint.largestApplicable];
		}

		const yesterday = stayNights[i - 1]
		const yesterdayCompound = yesterday ? yesterday.compoundPrice : 0
		stayNights[i].compoundPrice =
			yesterdayCompound + stayNights[i].price;
	}
	return stayNights;
};

/**
 * Builds LOS records for a specific night.
 *
 * @param bookableNights {BookableNights}
 * @param checkInNight {BookableNight}
 * @returns {number[]}
 */
export const buildLOSForCheckInNight = (
	bookableNights: BookableNights,
	checkInNight: BookableNight
): number[] => {
	const los: number[] = [];
	let stayNights: StayNight[] = [];

	// iterate over each possible LOS regenerating as we go
	for (let nightOfStay = 1; nightOfStay <= MAX_STAY_NIGHTS; nightOfStay++) {
		// get the price for the next night of the stay
		const nightPrice = getPriceInXDays(
			checkInNight.date,
			nightOfStay - 1,
			bookableNights
		);

		//  if the night does not have a price we break as we hit a bokability cliff
		if (!nightPrice) {
			break
		}

		// otherwise we refresh all StayNights for the new LOS
		stayNights = refreshStayNights(stayNights, nightOfStay);

		// we get the price for the new night
		const pricePoint = getAvailablePricePoint(nightPrice.price, nightOfStay);
		const pricedAtForLOS = nightPrice.price[pricePoint.largestApplicable];
		const yesterday = stayNights[nightOfStay - 2];
		const yesterdayCompound = yesterday ? yesterday.compoundPrice : 0;
		//  build a new stay price based on today plus updated compound
		const newStayNight = {
			night: nightPrice,
			price: pricedAtForLOS,
			compoundPrice: pricedAtForLOS + yesterdayCompound,
			nextCheckpoint: pricePoint.nextApplicable
		}
		// push it to the array
		stayNights.push(newStayNight)

		if (nightOfStay >= checkInNight.minStay) {
			los.push(newStayNight.compoundPrice);
		} else {
			los.push(NO_STAY_VALUE);
		}
	}
	return los;
};
