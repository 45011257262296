import * as FullStory from '@fullstory/browser';

/**
 * The FS module has no notion of serverside events. This function ensures that
 * FS is loaded and running before firing off custom events and should be used by
 * any hooks or helper functions within the tracking module.
 * @param trackingEvent
 */
export const onFSReady = (
    eventName: string,
    eventContext: Record<string, any>,
) => {
    if (typeof window !== 'undefined') {
        if (FullStory.isInitialized()) {
            console.log('sending custom event to FS')
            FullStory.event(eventName, eventContext);
        } else {
            console.log('FS not initialized, not sending custom event')
        }
    }
}
