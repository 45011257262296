import { fromFloyd } from 'components/CalendarWidget/mapping/pricing-helpers';
import { getCurrencySymbol } from 'state/atoms/pricing';
import { IDirectDiscount } from 'types/content';

export const getAppliedDiscount = (
  codeUnderTest: any,
  propertyDiscounts: IDirectDiscount[]
) => {
  if (codeUnderTest === undefined) {
    return undefined
  }

  if (typeof codeUnderTest != 'string')  {
    return null;
  }

  return propertyDiscounts.find((d, _i) => {
    return (
      d.discountCode === codeUnderTest &&
      (!d.expiresAt || new Date(d.expiresAt) >= new Date()) &&
      d.active
    );
  }) ?? null;
}

export const formatDiscountAmount = (discount: IDirectDiscount, currency: string) => {
  if (discount.discountType === 'fixed') {
      return `${getCurrencySymbol(currency)}${fromFloyd(discount.discountAmount).toFixed(2)}`
  } else if (discount.discountType ==='percentage') {
      return `${discount.discountAmount * 100}%`
  }
}
