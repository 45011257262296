import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

const RoundButtonDiv = styled.div<{ disabled?: boolean }>`
  ${(props) => typographyGenerator('button', props.theme)}
  border-width: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  background-color: ${(props) => props.disabled ? props.theme?.colors.LM_N20 : 'rgba(41, 55, 73, 0.05)'};
  border-radius: 16px;
  color: ${(props) => props.theme?.colors.LM_NU100};
  align-self: stretch;
  flex-grow: 0;
  text-align: center;
  transition: background-color 300ms ease-out;
  outline: none;
  transition: outline 300ms ease-out;
  :hover {
    transition: background-color 300ms ease-out;
    background-color: rgba(213, 214, 216, 1); /* ${(props) => props.theme?.colors.LM_N80};*/
    cursor: pointer;
    outline: none;
  }
  :active {
    outline: 2px solid ${(props) => props.theme?.colors.BB90}; 
    transition: outline 300ms ease-out;
    cursor: pointer;
  }
`;

const RoundButtonImage = styled.div`
  display: flex;
  min-width: 24px;
  min-height: 24px;
  justify-content: center;
  align-items: center;
`;

interface IRoundButton {
  buttonImage: any;
  handleClick?: MouseEventHandler | undefined;
  disabled?: boolean;
  className?: string;
}

const RoundButton: React.FC<IRoundButton> = ({
  buttonImage,
  handleClick,
  disabled = false,
  className
}) => {
  return (
    <RoundButtonDiv
      onClick={(evt) => {
        if(disabled) return;
        evt.preventDefault();
        evt.stopPropagation();
        handleClick && handleClick(evt);
      }}
      disabled={disabled}
      className={className}
    >
      <RoundButtonImage>{buttonImage}</RoundButtonImage>
    </RoundButtonDiv>
  );
};

export default RoundButton;
