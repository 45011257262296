import styled from 'styled-components';
import PricingCell from 'components/PricingCell';
import typographyGenerator from 'typography/generator';
import { ILineItem } from 'types/pricing';

const StayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;
    gap: 8px;
    width: 100%;
    min-height: 120px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.03)) drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.03));
    border-radius: 16px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 100%;
`;

const GreyTextContainer = styled.div`
    ${(props => typographyGenerator('body', props.theme))};
    padding: 16px 0px;
`;

const DateNotAvailableContainer = styled.div`
    ${(props => typographyGenerator('body', props.theme))};
    color: ${(props => props.theme?.colors.RE100)};
    padding: 16px 0px;
`;

export interface IPriceBreakdown {
    isDateSelected: boolean,
    hasAvailability: boolean,
    currency: string,
    baseStayPrice: number,
    totalBeforeDiscount: number,
    totalToPayToday: number,
    minStay: number,
    lineItems: ILineItem[],
    error?: string
}


interface IStayPriceBreakdownProps {
    title: string;
    checkInDate: string;
    checkOutDate: string;
    stayPriceBreakdown: IPriceBreakdown | null;
    discountInvalid: boolean,
};

const StayPriceBreakdown: React.FC<IStayPriceBreakdownProps> = ({
    title = '',
    checkInDate,
    checkOutDate,
    stayPriceBreakdown,
    discountInvalid,
}) => {
    if (checkInDate && checkOutDate && stayPriceBreakdown) {
        console.log('stayPriceBreakdown', stayPriceBreakdown)
        const errorMessage = stayPriceBreakdown.error ?? 'No availability for selected dates';
        return (
            <>
                {stayPriceBreakdown.hasAvailability ?
                    <StayContainer>
                        <TextContainer>
                            <PricingCell
                                totalBeforeReduction={stayPriceBreakdown.totalBeforeDiscount ?? 0}
                                totalAfterReduction={stayPriceBreakdown.totalToPayToday ?? 0}
                                title={title}
                                currency={stayPriceBreakdown.currency ?? ''}
                                lineItems={stayPriceBreakdown.lineItems ?? []}
                                itemVerticalPadding={8}
                                discountInvalid={discountInvalid}
                            />
                        </TextContainer>
                    </StayContainer>
                    :
                    <DateNotAvailableContainer>
                        {errorMessage}
                    </DateNotAvailableContainer>
                }
            </>
        )
    }
    else {
        return (
            <TextContainer>
                <GreyTextContainer>
                    Add dates for prices
                </GreyTextContainer>
            </TextContainer>
        )
    }
}

export default StayPriceBreakdown;
