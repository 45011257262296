import { Temporal } from '@js-temporal/polyfill';
import { DayOfWeek } from '../../../types/pricing';

// We have to have old Date objects in these helpers as that's what we get out of the
// Mobiscroll calendar

/**
 * Get the local date of either a Date object, or for the current local time
 * @param date {Date} (optional) the date to determine
 */
export const getLocalTemporalDate = (date?: Date) => {
  if (date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return new Temporal.PlainDate(year, month, day);
  }
  return Temporal.Now.plainDateISO(); // local TZ by default
};

/**
 * Get the UTC-equivalent date of either a Date object, or for the current UTC time
 * @param date {Date} (optional) the date to determine
 */
export const getUTCTemporalDate = (date?: Date) => {
  if (date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // months are 0 indexed in Date
    const day = date.getUTCDate();
    return new Temporal.PlainDate(year, month, day);
  }
  return Temporal.Now.plainDateISO('UTC');
};

// 0th element is Monday for Temporal (this is different to Date, which is Sunday)
const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const;

/**
 * Determine the day of the week for a given date.
 * @param date {string} the date to determine.
 */
export const dayOfWeek = (date: string): DayOfWeek =>
  DAYS[Temporal.PlainDate.from(date).dayOfWeek - 1];

export const toPlainDateString = (date: Date): string => {
  const plainDate = getLocalTemporalDate(date);
  const year = plainDate.year.toString().padStart(4, '0');
  const month = plainDate.month.toString().padStart(2, '0');
  const day = plainDate.day.toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
