import { useEffect } from 'react';
import styled from 'styled-components';
import RoundButton from 'components/RoundButton';
import CloseImage from 'public/icons/close.svg';

const Background = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  height: 100vh;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 300ms ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const Container = styled.div<{ show: boolean; yPos: string }>`
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: ${(props) => (props.show ? '100vh' : '0px')};
  z-index: 1010;
  overflow: hidden;
`;

const Panel = styled.div<{ show: boolean; yPos: string }>`
  @media screen and (max-width: 375px) { /* fix bottom bar overlapping panel on small screen apple device */
    min-height: -webkit-fill-available;
  }
  display: flex;
  width: 100%;
  height: ${(props) => `${props.yPos}%`};
  overflow: hidden;
  transition: transform 300ms ease-out;
  position: fixed;
  transform: ${(props) =>
    props.show
      ? `translateY(-calc(${props.yPos}vh)))`
      : 'translateY(calc(100vh))'};
  background-color: white;
  z-index: 1020;
  bottom: 0px;
  border-radius: ${(props) => props.yPos === '100' ? '0px' : '16px 16px 0px 0px'};  
`;

const CloseButtonContainer = styled.div<{ showCloseButton: string }>`
  display: flex;
  justify-content: ${(props) =>
    props.showCloseButton === 'left' ? 'flex-start' : 'flex-end'};
  width: 100%;
  z-index: 1002;
  position: absolute;
  padding: 16px 20px;
  visibility: ${(props) =>
    props.showCloseButton === 'hidden' ? 'hidden' : 'visible'};
`;

interface ISlidingPanel {
  show: boolean;
  children: any;
  showCloseButton?: 'left' | 'right' | 'hidden';
  closeIcon?: JSX.Element;
  handleCloseClick?: () => void;
  className?: string;
  yPos?: '25' | '50' | '75' | '100';
}

const SlideUpPanel: React.FC<ISlidingPanel> = ({
  show = false,
  children,
  showCloseButton = 'right',
  closeIcon = <CloseImage />,
  handleCloseClick,
  className,
  yPos = '100',
}) => {

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    }
  }, [show]);

  return (
    <Container show={show} className={className} yPos={yPos}>
      <Background show={show} />
      <Panel show={show} className={className} yPos={yPos}>
        <CloseButtonContainer showCloseButton={showCloseButton}>
          <RoundButton
            buttonImage={closeIcon}
            disabled={false}
            handleClick={handleCloseClick}
          />
        </CloseButtonContainer>
        {children}
      </Panel>
    </Container>
  );
};

export default SlideUpPanel;
