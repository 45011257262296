import styled from 'styled-components';
import { HTMLInputTypeAttribute, ChangeEventHandler, FocusEventHandler, ReactNode } from 'react';
import typographyGenerator from '../../typography/generator';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;    
    border-color: ${(props => props.theme?.fonts?.label.color)};
    width: 100%;
    position: relative;
`;

const Input = styled.input<{error: any | undefined, showOptionArrow?: boolean}>`
    ${(props => typographyGenerator('body', props.theme))}
    border: 2px solid transparent;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    width: 100%;
    min-width: 279px;
    height: 56px;
    background: ${(props => props.theme?.colors.LM_N20)};
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    color: ${(props => props.theme?.colors.LM_N100)};
    transition: background-color 300ms ease-out;
    :focus{
        border: 2px solid ${(props => props.theme?.colors.BB100)};
    }
    ::placeholder {
        color: ${(props => props.theme?.colors.LM_N50)};
    }
    :invalid {
        border: 2px solid ${(props => props.theme?.colors.PI100)};
    }
    ${(props) => props.error && `
        border: 2px solid ${props.theme?.colors.PI100};
    `};
    :hover {
        transition: background-color 300ms ease-out;
        background-color: ${(props => props.theme?.colors.LM_N30)};
    }
    :focus:hover {
        background: ${(props => props.theme?.colors.LM_N20)} !important;
        transition: background-color 300ms ease-out;
    }
    :focus::placeholder {
        transition: color 300ms ease-out;
        color: ${(props => props.theme?.colors.LM_N20)};
    }
    ${(props) => props.showOptionArrow && `
        background-image: url('/icons/chevron-down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        appearance: none;
    `};
    @media screen and (max-width: 375px) {
        min-width: 180px;
    }
`;

const DescriptionLabel = styled.label`
    ${(props => typographyGenerator('label', props.theme))}
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    line-height: 22px;
    color: ${(props => props.theme?.colors.LM_N60)}
`;

const ErrorLabel = styled.label`
    ${(props => typographyGenerator('label', props.theme))}
    margin-top: 4px;
    padding: 2px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    line-height: 22px;
    color: ${(props => props.theme?.colors.RE100)};
`;

const ErrorIcon = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 10px;
    height: 100%;
    align-items: center;
    color: ${(props => props.theme?.colors.RE100)};
`;

interface StyledInput {
    type?: HTMLInputTypeAttribute;
    name?: string | undefined;
    placeholder?: string;
    error?: string;
    errorIcon?: ReactNode | undefined;
    description?: string;
    handleChange?: ChangeEventHandler<any> | undefined;
    handleBlur?: FocusEventHandler<any> | undefined;
    value: string | ReadonlyArray<string> | number | undefined;
    handleClick?: ()=>void;
    showOptionArrow? :boolean;
    readOnly?: boolean;
    className?: string;
}

const StyledInput: React.FC<StyledInput> = ({ type, name, placeholder, handleChange, handleBlur, value, description, error, errorIcon, handleClick, showOptionArrow = false, readOnly = false, className }) => {
    return (
        <Container>
            <InputContainer>
                <Input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                    error={error}
                    showOptionArrow={showOptionArrow}
                    onClick={handleClick}
                    readOnly={readOnly}
                    className={className}
                />
                {error && errorIcon ? <ErrorIcon>{errorIcon}</ErrorIcon> : null}
            </InputContainer>
            {error ? <ErrorLabel>{error}</ErrorLabel> : null}
            {description ? <DescriptionLabel>{description}</DescriptionLabel> : null}
        </Container>
    );
}

export default StyledInput
