import { Temporal } from '@js-temporal/polyfill';
import { DayOfWeek, IPricing, PricingGap, PricingOrGap } from '../../../types/pricing';
import { dayOfWeek } from './date-helpers';


/**
 * Determine if an input object is a PricingGap
 * @param inp {IPricing | PricingGap} the object to test
 * @returns True if it is a pricing gap
 */
export const isPricingGap = (inp: IPricing | PricingGap): inp is PricingGap => {
    return (inp as IPricing).property_id === undefined;
};

/**
 * Determine if any stays are allowed to check in on a given date.
 * @param checkIn {string} - The date to check
 * @param checkInPeriod {PricingOrGap} - The period to check the date for.
 * @returns {boolean} - True if any stay is allowed, false otherwise
 */
export const anyCheckInAllowed = (checkIn: string, checkInPeriod: PricingOrGap): boolean => {
    if (isPricingGap(checkInPeriod)) {
        return false;
    } else {
        return (checkInPeriod as IPricing).los_availability[dayOfWeek(checkIn)].some((v) => v);
    }
}

/**
 * Determine if a pricing period allows a stay of a given length.
 *
 * @param checkInDayOfWeek {DayOfWeek} - The check in day of week.
 * @param lengthOfStay {number} - The number of nights.
 * @param pricing {IPricing} - The pricing period.
 */
export const periodAllowsStayOfLength = (checkInDayOfWeek: DayOfWeek, lengthOfStay: number, pricing: IPricing) => {
    if (lengthOfStay > 7) {
        return pricing.los_availability[checkInDayOfWeek][6] === true;
    }

    return pricing.los_availability[checkInDayOfWeek][lengthOfStay - 1];
};

/**
 * For a given checkIn date, length of stay and pricing: find the pricing periods/gaps that the stay involves,
 * and how many days the stay has in each of those periods.
 *
 * **Note** that it is assumed that:
 * - the pricing/gaps are sorted by date.
 * - the first pricing/gap is when the stay will start.
 * - the pricing/gaps cover the entire stay.
 *
 * @param checkIn {string} - The start date of the stay
 * @param nights {number} - The number of nights of that stay
 * @param pricing {PricingOrGap[]} - The pricing periods/gaps that comprise this stay.
 * @returns  The substay lengths and associated periods/gaps.
 */
export const periodsAndDaysPerPeriod = (checkIn: string, nights: number, pricing: PricingOrGap[]): [number, any][] => {
    // Shaving one off nights because the checkIn date counts as the first night.
    const checkInDate = Temporal.PlainDate.from(checkIn);
    const nightsInclusive = nights - 1;
    const lastNightDate = checkInDate.add({ days: nightsInclusive });

    // Stay fits entirely in the first pricing period
    if (Temporal.PlainDate.compare(lastNightDate, pricing[0].end_date) <= 0) return [[nights, pricing[0]]];

    const endingPeriodIdx = pricing.findIndex((pp: any) => Temporal.PlainDate.compare(lastNightDate, pp.start_date) >= 0 && Temporal.PlainDate.compare(lastNightDate, pp.end_date) <= 0);
    const startPeriodIdx = pricing.findIndex((pp: any) => Temporal.PlainDate.compare(checkInDate, pp.start_date) >= 0 && Temporal.PlainDate.compare(checkInDate, pp.end_date) <= 0);
    const periodsInUse = pricing.slice(startPeriodIdx, endingPeriodIdx + 1);

    return periodsInUse.map((pp, idx, arr) => {
        const startDateTemporal = Temporal.PlainDate.from(pp.start_date);

        // +1s because until is exclusive of the end date
        if (idx === 0) return [checkInDate.until((pp.end_date)).days + 1, pp];

        // Last period: from start of period to end-date
        if (idx === arr.length - 1) return [startDateTemporal.until(lastNightDate).days + 1, pp];

        // Middle period, stay encapsulates entire pricing period
        return [startDateTemporal.until(pp.end_date).days + 1, pp];
    });
};

/**
 * 
 * @param floydPrice {number} - The calculated price in floyd format
 * @returns The calculated price in normalized format
 */
export const fromFloyd = (floydPrice: string | number) => (Number(floydPrice) || 0) / 10000;
