import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 32px;
    @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
        grid-column-gap: 24px;
      }
`;

const Index = (props: any) => {
    return <Grid>{props.children}</Grid>;
};

export default Index;
