import { useEffect } from 'react';
import styled from 'styled-components';

const Background = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'flex')};
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  height: ${(props) =>
    props.show ? '100vh' : '0'};
  z-index: 1010;
  background-color: rgba(0,0,0,0.4);
  transition: opacity 300ms ease-out;
  opacity: ${(props) => props.show ? 1 : 0};
`;

const Container = styled.div<{ show: boolean }>`
  display: flex;  
  width: ${(props) =>
    props.show ? '100vw' : '0'};
  position: fixed;
  top: 0px;
  left: 0px;
  height: ${(props) =>
    props.show ? '100vh' : '0'};
  z-index: 1010;
  overflow: hidden;
`;

const Panel = styled.div<{ show: boolean }>`
  display: flex;
  overflow: hidden;
  transition: background-color 300ms ease-out;
  position: absolute;
  padding: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 1020;
  transition: opacity 300ms ease-out;
  opacity: ${(props) =>
    props.show ? 1 : 0}
`;

interface IModal {
  show: boolean;
  children: any;
  handleCloseClick?: () => void;
  className?: string;
}

const SlideUpPanel: React.FC<IModal> = (props) => {
  const { show = false, children, handleCloseClick, className } = props;

  useEffect(() => {
      document.body.style.overflow = show ? 'hidden': '';
  }, [show]);

  return (
    <Container show={show} className={className}>
      <Background show={show} onClick={handleCloseClick} />
      <Panel show={show} className={className}>
        {children}
      </Panel>
    </Container>
  );
};

export default SlideUpPanel;
