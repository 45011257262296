import moment from 'moment';

import { BookableNights, NightlyLOS } from './types';
import {
	formatDate,
	getDatesInRange,
	getDayOfWeek,
	arrayOrEmptyIfAllZeroes,
} from './utils';
import { buildLOSForCheckInNight } from './bookable-night';
import { ILOS, ILOSRecord, IPricing } from 'types/pricing';
import { IAvailability } from 'types/availability-rules';

const FLOYDIAN_FACTOR = 10000;
const MIN_ADVANCED_NOTICE_DAYS = 1;

const roundTwoDecimals = (value: number) => {
	return (Math.round(value * 100) / 100);
};

/**
 * Builds an object of nights which are bookable (have availability and pricing). Each night has base pricing
 * as well as prospective pricing if any of the periods discounts were applied. Each night also has whether
 * or not it is eligible for check-in.
 *
 * @param pricing {IPricing[]}
 * @param availability {IAvailability[]}
 * @returns {BookableNights} - a set of priced and available nights with or without check-in eligibility
 */
const getBookableNights = (
	pricing: IPricing[],
	availability: IAvailability[],
	bookingFee: number,
	fxRate: number,
	minBookingNotice: number
): BookableNights => {
	const dates: BookableNights = {};
	// get the date two years from now; we do not want to price beyond that
	const now = moment().startOf('day');
	const firstToPrice = now.clone().add(minBookingNotice, 'days');
	const lastToPrice = now.clone().add(730, 'days');
	// expand the array of available dates into a set with an element for each available date
	const unblockedDates = new Set(
		availability.flatMap((period) => {
			const startDate = moment(period.start_on);
			const endDate = moment(period.end_on).add(-1, 'days');
			const range = getDatesInRange(
				moment.max(startDate, firstToPrice),
				moment.min(endDate, lastToPrice)
			).map((d) => d.valueOf());
			return range;
		})
	);

	// iterate over each pricing period and expand these into individual bookable days
	pricing.forEach((period, idx) => {
		const startDate = moment(period.start_date);
		const endDate = moment(period.end_date);

		// throw out any pricing periods that are in the past
		if (endDate > now) {
			// get an array of all dates inside the pricing window
			const datesInPeriod = getDatesInRange(
				startDate,
				moment.min(lastToPrice, endDate)
			);
			// ensure those dates are also available; these days are bookable
			const filteredDatesInPeriod = datesInPeriod.filter((d) =>
				unblockedDates.has(d.valueOf())
			);
			// build pricing model for each individual day
			filteredDatesInPeriod.forEach((date) => {
				// get the week of (base) pricing and availability
				const dayOfWeek = getDayOfWeek(date);
				const weekOfPrices = period.prices.los[dayOfWeek];
				const weekOfAvailability = period.los_availability[dayOfWeek];
				// the number of false elements in the los_availability array indicates the minimum stay period
				// - if all seven days are blocked as false it means check-in cannot happen on this day
				// - else if N days are false it means the minimum stay is N-1 days
				const trueIdx = weekOfAvailability.findIndex((n) => n === true);
				const canCheckIn = trueIdx !== -1;
				const minStay = trueIdx + 1;
				// append the date to the map with pricing per night (including pricing when any discounts are applied)
				// consumers can assume that pricing will ALWAYS be sorted in order of increasing minimum stay
				const discounts = period.discounts ?? [];
				// the booking fee can be applied on an individual nightly basis for performance
				const basePrice =
					roundTwoDecimals(
						(weekOfPrices[0] / FLOYDIAN_FACTOR) * (bookingFee + 1) * fxRate
					);
				dates[formatDate(date)] = {
					date,
					price: discounts.reduce(
						(obj, discount) => ({
							...obj,
							[discount.nights.toString()]:
								discount.factor * basePrice,
						}),
						{ '0': basePrice }
					),
					canCheckIn,
					minStay,
					period: idx,
				};
			});
		}
	});
	return dates;
};

/**
 * Generates LOS records for each bookable night iterating through each night
 * until we hit an unbookable date (up to a maximum of 62 nights).
 *
 * @param bookableNights {BookableNights}
 * @returns {NightlyLOS[]}
 */
const buildLOSForBookableStays = (
	bookableNights: BookableNights
): NightlyLOS[] =>
	Object.entries(bookableNights)
		// exclude any nights where we can't check in (stays starting on this night are unbookable)
		.filter(([_checkInDateStr, checkInNight]) => checkInNight.canCheckIn)
		// generate LOS records for each individual night
		.map(([checkInDateStr, checkInNight]) => {
			const los = arrayOrEmptyIfAllZeroes(
				buildLOSForCheckInNight(bookableNights, checkInNight)
			);
			return {
				checkInDateStr,
				checkInDate: checkInNight.date,
				los,
			};
		});

/**
 * The transformation handler. This takes TravelNest availability and pricing
 * and converts it into a parsable pricing model.
 *
 * @param content {IContent}
 * @param bookingFee {number}
 * @returns {ILOS}
 */
export const buildLOS = (
	availability: IAvailability[],
	pricing: IPricing[],
	capacity: number,
	advanceBookingDays: number,
	fxRate: number,
	bookingFee: number
): ILOS => {
	// ensure that we have pricing
	if (!pricing || pricing.length === 0) {
		return {};
	}

	// generated dates should respect the booking notice
	// an extra day should be added to the booking notice if we sync after 10pm
	// this ensures that batch pricing updates near midnight also make the following
	// day unavailable.
	const minBookingNotice = Math.max(MIN_ADVANCED_NOTICE_DAYS, advanceBookingDays)

	// dates in the map have have a nightly price with and without discounts
	// dates in the map are unblocked on the calendar
	// dates in the map are or are not available for check in
	const bookableNights = getBookableNights(pricing, availability, bookingFee, fxRate, minBookingNotice);
	console.info(`Got bookable nights ${Date.now()}`);

	// array elements have a check-in date
	// array elements have compound LOS pricing for stay lengths of up to 62 days
	// array elements have compound LOS pricing has any discounts applied
	// array does not contain dates that are unavailable for check-in
	const compoundNightlyPricing = buildLOSForBookableStays(bookableNights);
	console.info(`Got compound pricing ${Date.now()}`);

	// dates in the map adhere to all of the above
	// dates in the map have pricing duplicated for all allowed property capacities
	// dates in the map are excluded if a night had no priceable dates
	const los: Record<string, ILOSRecord[]> = {};
	compoundNightlyPricing.forEach((night) => {
		if (night.los.length > 0) {
			los[night.checkInDateStr] = [
				{
					guests: capacity,
					price: night.los,
				},
			];
		}
	});

	// return in the expected format
	return los;
};

/**
 * Check if a stay is allowed for a given check in, check out and guest count.
 *
 * @param checkInDate {string} - the date the guest wishes to check in
 * @param checkOutDate {string} - the date the guest wishes to check out
 * @param guestCount {number} - the number of guests in the booking
 * @param los {ILOS} - the los object for the property
 */
export const getStayPrice = (
	checkInDate: string,
	checkOutDate: string,
	guestCount: number,
	los: ILOS
): {
	stayPrice: number;
	minNights: number;
} | { error: string } => {
	const checkInMoment = moment(checkInDate);
	const checkOutMoment = moment(checkOutDate);

    // check if the check in date is in the object
	const checkInDateStr = formatDate(checkInMoment);
	const checkInLOS = los?.[checkInDateStr];
	if (!checkInLOS) {
		return {
			error: 'There were no available stays for the requested check in date',
		};
	}

	// check if the number of guests is in the object
	const guestsLOS = checkInLOS.find((r) => guestCount <= r.guests);
	if (!guestsLOS) {
		return {
			error: 'There were no available stays for the requested number of guests',
		};
	}

	// check if there are enough nights in the object
	const minNightsIdx = (guestsLOS.price ?? []).findIndex((p) => p > 0);
	if (minNightsIdx === undefined) {
		return {
			error: 'There were no available stays for the requested number of nights',
		};
	}

	// check if the requested stay is too short
	const minNights = minNightsIdx + 1;
	const nights = checkOutMoment.diff(checkInMoment, 'days');
	if (minNights > nights) {
		return {
			error: `A stay starting on this date must be at least ${minNights} nights long`,
		};
	}

	// check the actual night is available
	const stayPrice = guestsLOS.price?.[nights - 1];
	if (!stayPrice) {
		return {
			error: 'There were no available stays for the requested number of nights',
		};
	}

	// check that the stay price is greater than zero
	if(stayPrice <= 0) {
		return {
			error: 'The requested stay is not available',
		};
	}

	return {
		stayPrice,
		minNights,
	};
};
