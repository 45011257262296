import { getCurrencySymbol } from 'state/atoms/pricing';
import { IDirectDiscount } from 'types/content';
import { ILineItem } from 'types/pricing';
import { fromFloyd } from './pricing-helpers';

export const calculateDiscount = (basePrice: number, currency: string, discount: IDirectDiscount): ILineItem | null => {
    const symbol = getCurrencySymbol(currency);
    if (discount.discountType === 'fixed') {
        const discountAmount = fromFloyd(discount.discountAmount)
        return {
            timeOfCollection: 'BOOKING',
            amount: discountAmount * -1,
            description: `${symbol}${(discountAmount).toFixed(2)} Discount`
        }
    } else if (discount.discountType === 'percentage') {
        const discountAmount = basePrice * discount.discountAmount * -1;
        return {
            timeOfCollection: 'BOOKING',
            amount: discountAmount,
            description: `${symbol}${Math.abs(discountAmount).toFixed(2)} Discount`
        }
    } else {
        return null;
    }
}
