import React, { useEffect, useCallback, useState, Dispatch } from 'react';
import { Datepicker, setOptions } from '@mobiscroll/react';
import { IAvailableDates } from 'types/availability-rules';
import { SetStateAction } from 'react';
import { toPlainDateString } from './mapping/date-helpers';
import { ILOS } from 'types/pricing';
import { add } from 'date-fns';

setOptions({
    theme: 'ios',
    themeVariant: 'light'
});

type StayDatesSetter = (_dates: { startOn: string, endOn: string }) => any;

interface ICalendarWidgetProps {
    showWidgetState: [boolean, Dispatch<SetStateAction<boolean>>];
    los: ILOS;
    setStayDates: StayDatesSetter;
    firstSelectableDate: string;
}

interface IMobiscrollChangeEvent {
    value: [Date, Date];
}

const CalendarWidget: React.FC<ICalendarWidgetProps> = ({
    showWidgetState,
    los,
    firstSelectableDate,
    setStayDates
}) => {
    // STATE: Data
    const [selectedDates, setSelectedDates] = useState<(Date | null)[]>();
    const [validDates, setValidDates] = useState<IAvailableDates[]>([]);

    // STATE: Rendering
    const [showCalendar, setShowCalendar] = showWidgetState;

    // HOOK: On Date Picked
    const onChange = useCallback((event: IMobiscrollChangeEvent) => {
        setSelectedDates(event.value);
        if (event.value[0] !== null && event.value[1] !== null) {
            const start = toPlainDateString(event.value[0])
            const end = toPlainDateString(event.value[1])
            setStayDates({
                startOn: start,
                endOn: end,
            })
        }
    }, [setStayDates]);

    // HOOK: Pricing x Availability Updates
    useEffect(() => {
        if ('error' in los) {
            // TODO: Handle the error and pass back
            console.error(los.error);
        } else {
            const availableDates = Object.entries(los).map(([startDate, [{ price }]]) => {
                return {
                    start: startDate,
                    end: toPlainDateString(add(new Date(startDate), { days: price.length }))
                }
            });
            setValidDates(availableDates);
        }
    }, [los]);

    return (
        <Datepicker
            responsive={
                {
                    custom: {
                        breakpoint: 380, //Override xsmall at this size
                        display: 'center',
                        pages: 1,
                        touchUi: true,
                    },
                    xsmall: {
                        display: 'bottom',
                        pages: 1,
                        touchUi: true,
                        theme: 'ios',
                    },
                    small: {
                        display: 'center',
                        pages: 1,
                        touchUi: true,
                    },
                    medium: {
                        display: 'center',
                        pages: 1,
                        touchUi: true
                    },
                    large: {
                        display: 'center',
                        pages: 2,
                        touchUi: false
                    },
                    xlarge: {
                        display: 'center',
                        pages: 2,
                        touchUi: false
                    }
                }
            }
            onChange={onChange}
            isOpen={showCalendar}
            onClose={() => {
                setShowCalendar(false);
            }}
            inputComponent="IonInput"
            returnFormat="jsdate"
            value={selectedDates}
            cssClass="md-book-rental"
            controls={['calendar']}
            select="range"
            calendarScroll="horizontal"
            calendarType="month"
            min={firstSelectableDate}
            showRangeLabels={true}
            inRangeInvalid={false}
            rangeHighlight={true}
            valid={validDates}
            rangeStartLabel="Check-in"
            rangeEndLabel="Check-out"
            headerText="Select dates"
            dateFormat="DD MMM YY"
            firstDay={1}
        />
    );
}

export default CalendarWidget;
