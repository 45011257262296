import { useEffect, useMemo } from 'react';
import { IDirectDiscount } from 'types/content';
import { DiscountApplicationState, sendDiscountEvent } from 'lib/tracking/discount';

/**
 * Hook fires a discount event into FS on every change to the discountState.
 * If the state does not change this hook will not fire. I.e successive attempts
 * to enter an UNRECOGNISED discount code will result in a single event.
 * @param discountState
 * @param context
 */
export const useSendDiscountEvent = (
  applicationState: DiscountApplicationState,
  applicationContext: string
) => {
  useMemo(() => {
      sendDiscountEvent(applicationState, applicationContext);
  }, [applicationState, applicationContext]);
};

/**
 * Hook fires a discount event into FS when a funnel page is loaded
 * with a discount applied. It is assumed that this hook should only
 * fire once on pageload.
 * @param appliedDiscount a direct discount or some nullish vaue
 */
export const useSendDiscountPageLoadEvent = (
  appliedDiscount: IDirectDiscount | null | undefined
) => {
  useEffect(() => {
      const discountState =
      appliedDiscount === undefined
        ? 'NOT_PROVIDED'
        : appliedDiscount === null
        ? 'UNRECOGNISED'
        : 'APPLIED';
      sendDiscountEvent(discountState, 'PAGE_VISIT');
  }, []);
};
