import { useState } from 'react';
import styled from 'styled-components';
import TableCell from 'components/TableCell';
import Panel from 'components/Panel';
import StyledStepper from 'components/Stepper';
import { CancelButton, ActionButton } from 'components/StyledModalButtons';

const Container = styled.div`
    display: flex;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Card = styled(Panel)`
    width: 100%;
`;

interface IGuestCard {
    className?: string;
    maxGuests: number;
    defaultValue: { adults: number, children: number };
    handleOk: (_adults: number, _children: number) => void;
    handleCancel: () => void;
}

const GuestCard: React.FC<IGuestCard> = ({ className = '', maxGuests, handleOk, handleCancel, defaultValue = { adults: 0, children: 0 } }) => {
    const [adults, setAdults] = useState(defaultValue.adults);
    const [children, setChildren] = useState(defaultValue.children);

    return (
        <Container className={className}>
            <Card>
                <Row>
                    <TableCell title="Select Guests" actionTitle="" items={[`Maximum ${maxGuests} guests`]} />
                </Row>
                <Row>
                    <TableCell title="Adults" actionTitle="" items={['Age 13+']} />
                    <StyledStepper min={1} max={maxGuests - children} defaultValue={adults} onChange={setAdults} />
                </Row>
                <Row>
                    <TableCell title="Children" actionTitle="" items={['Age 2-12']} />
                    <StyledStepper min={0} max={maxGuests - adults} defaultValue={children} onChange={setChildren} />
                </Row>
                <Row>
                    <CancelButton title="Cancel" handleClick={handleCancel} />
                    <ActionButton title="OK" handleClick={() => handleOk(adults, children)} />
                </Row>
            </Card>
        </Container>
    );
}

export default GuestCard;
