import moment, { Moment } from 'moment';
import { DayOfWeek, BookableNights, BookableNight } from './types';

export const getDatesInRange = (start: Moment, end: Moment): Moment[] => {
	const dates = [];
	const currentDate = moment(start);
	while (currentDate <= end) {
		dates.push(currentDate.clone());
		currentDate.add(1, 'day');
	}
	return dates;
};

export const getDayOfWeek = (date: Moment): DayOfWeek =>
	date.format('ddd').toLowerCase() as DayOfWeek;

export const formatDate = (date: Moment): string => date.format('YYYY-MM-DD');

export const getPriceInXDays = (
	currentDate: Moment,
	days: number,
	nightlyPrices: BookableNights
): BookableNight | undefined => {
	const tomorrow = currentDate.clone().add(days, 'days');
	const tomorrowStr = formatDate(tomorrow);
	return nightlyPrices[tomorrowStr];
};

export const arrayOrEmptyIfAllZeroes = (los: number[]) =>
	los.some((n) => n !== 0) ? los : [];
