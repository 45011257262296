import styled from 'styled-components';
import typographyGenerator from 'typography/generator';
import { MouseEventHandler } from 'react';

const Button = styled.button`
    ${(props => typographyGenerator('body', props.theme))}
    border: solid 2px transparent;
    padding: 0px 16px;
    min-width: 56px;
    height: 36px;
    background-color: ${(props) => props.disabled ? props.theme?.colors.LM_N20 : 'rgba(41, 55, 73, 0.05)'};
    border-radius: 8px;
    color: ${(props => props.theme?.colors.LM_N0)};
    text-align: center;
    @media screen and (min-width: 667px) {
        max-width: 279px;
    }
    transition: background-color 300ms ease-out;
    :focus{
        border: 2px solid ${(props => props.theme?.colors.BB100)};
    }
    :hover {
        cursor: pointer;
    }
    `


interface IStyledButton {
    title: string,
    type?: 'button',
    handleClick?: MouseEventHandler | undefined,
    disabled?: boolean,
    className?: string,
}

const StyledButton: React.FC<IStyledButton> = ({className, title, handleClick, type = 'button', disabled = false}) => {
    return (
        <Button className={className} type={type} onClick={handleClick} disabled={disabled}>{title}</Button>
    );
}

export default StyledButton
