import { onFSReady } from '.';

export type DiscountApplicationState =
  | 'NOT_PROVIDED'
  | 'UNRECOGNISED'
  | 'APPLIED';

export const sendDiscountEvent = (
  discountApplicationState: DiscountApplicationState,
  applicationContext: string,
  ) => {
  onFSReady('Discount Applied', {
    discountApplicationContext: applicationContext,
    discount: discountApplicationState,
  });
};
