import styled from 'styled-components';
import typographyGenerator from 'typography/generator';
import { useEffect, useState, MouseEventHandler } from 'react';
import MinusImage from 'public/icons/minus.svg';
import PlusImage from 'public/icons/plus.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 108px;
  height: 32px;
  justify-content: space-between;
`;

const StepperButtonDiv = styled.div<{ disabled?: boolean }>`
  ${(props) => typographyGenerator('body', props.theme)};
  border-width: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  background-color: ${(props) => props.disabled ? props.theme?.colors.LM_N20 : 'rgba(41, 55, 73, 0.05)'};
  border-radius: 16px;
  color: ${(props) => props.theme?.colors.LM_NU100};
  align-self: stretch;
  flex-grow: 0;
  text-align: center;
  transition: background-color 300ms ease-out;
  outline: none;
  transition: outline 300ms ease-out;
  :hover {
    transition: background-color 300ms ease-out;
    background-color: rgba(213, 214, 216, 1); /* ${(props) => props.theme?.colors.LM_N80};*/
    cursor: pointer;
    outline: none;
  }
  :active {
    outline: 2px solid ${(props) => props.theme?.colors.BB90}; 
    transition: outline 300ms ease-out;
    cursor: pointer;
  }
`;

const StepperImage = styled.div`
  display: flex;
  min-width: 24px;
  min-height: 24px;
  justify-content: center;
  align-items: center;
`;
const StepperCounter = styled.div`
  min-width: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
`;

interface IStyledStepper {
    max?: number;
    min?: number;
    defaultValue?: number;
    onChange?: (_value: number) => void | undefined;
    disabled?: boolean;
}

interface IStepperButton {
    buttonImage: any;
    handleClick?: MouseEventHandler | undefined;
    disabled: boolean
}

const StepperButton: React.FC<IStepperButton> = ({
    buttonImage,
    handleClick,
    disabled = false,
}) => {
    return (
        <StepperButtonDiv
            onClick={(evt) => {
                if(disabled) return;
                evt.preventDefault();
                evt.stopPropagation();
                handleClick && handleClick(evt);
            }}
            disabled={disabled}
        >
            <StepperImage>{buttonImage}</StepperImage>
        </StepperButtonDiv>
    );
};

const StyledStepper: React.FC<IStyledStepper> = ({
    max = Infinity,
    min = 0,
    defaultValue = 1,
    onChange,
    disabled = false,
}) => {
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        onChange && onChange(value);
    }, [value]);
    return (
        <Container>
            <StepperButton
                buttonImage={<MinusImage />}
                handleClick={() => {
                    value - 1 >= min && !disabled && setValue(value - 1);
                }}
                disabled={value - 1 < min}
            />
            <StepperCounter>{value}</StepperCounter>
            <StepperButton
                buttonImage={<PlusImage />}
                handleClick={() => {
                    value + 1 <= max && !disabled && setValue(value + 1);
                }}
                disabled={value + 1 > max}
            />
        </Container>
    );
};

export default StyledStepper;
