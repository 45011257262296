import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import SlideUpPanel from 'components/HOCS/SlideUpPanel';
import GuestCard from 'components/GuestCountCard';
import Modal from 'components/HOCS/Modal';

const GuestCardModal = styled(GuestCard)`
  min-width: 400px;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    min-width: 100vw !important;
  }
`;

const MobileSlideUp = styled(SlideUpPanel)`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    display: flex;
  }
`;

const ModalDesktop = styled(Modal)`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: flex;
  }
`;

interface IGuestWidget {
  maxGuests: number;
  showWidgetState: [boolean, Dispatch<SetStateAction<boolean>>];
  stayGuestsState: [{ adults: number, children: number }, (_: {adults: number, children: number}) => void];
}

const GuestWidget: React.FC<IGuestWidget> = ({ maxGuests, showWidgetState, stayGuestsState }) => {
  // STATE: Data
  const [stayGuests, setStayGuests] = stayGuestsState;

  // STATE: Rendering
  const [showWidget, setShowWidget] = showWidgetState;

  const ModelGuest: React.FC = () => (
    <GuestCardModal
      maxGuests={maxGuests}
      defaultValue={{ adults: stayGuests.adults, children: stayGuests.children }}
      handleOk={(adults, children) => {
        setStayGuests({ adults: adults, children: children });
        setShowWidget(false);
      }}
      handleCancel={() => setShowWidget(false)}
    />
  );

  return (
    <>
      <MobileSlideUp show={showWidget} yPos="50" showCloseButton="hidden">
        <ModelGuest />
      </MobileSlideUp>
      <ModalDesktop
        show={showWidget}
        handleCloseClick={() => setShowWidget(false)}
      >
        <ModelGuest />
      </ModalDesktop>
    </>
  );
};

export default GuestWidget;
